import React, {useEffect, useState} from "react";
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Stack,
    TextField,
    Typography,
    Switch
} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from "@mui/material/Button";
import {
    getAllDroneLocation,
    getAllManufacturers,
    getAllModels,
    getCalculation,
    getDronInfo, sendMail
} from "../services/calculation/api";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import '../scss/form.scss'
import TextFieldInput from "../helpers/textField";
import {check, initialFormState} from "./const";
import DialogActions from "@mui/material/DialogActions";
import NavBar from "./Navbar/NavBar";
import Footer from "./Footer";
import calculationImage from "./../img/calculationImage.png"


export default function Form({setOpen, setErrorMsg, handleClose}) {

    const [form, setForm] = useState(initialFormState)
    const [manufacturer, setManufacturer] = useState([]);
    const [allModels, setAllModels] = useState('')
    const [location, setLocation] = useState('')
    const [price, setPrice] = useState('')
    const [file, setFile] = useState([]);
    const [formKey, setFormKey] = useState(0);
    const [checked, setChecked] = useState(check);
    const [kaskoPrice, setKaskoPrice] = useState('');
    const [isKaskoVisible, setIsKaskoVisible] = useState(false);
    const [droneValue, setDroneValue] = useState(''); // Inicijaliziraj stanje


    const handleChangeManufacturer = async (event) => {
        const response = await getAllModels(event.target.value);
        setAllModels(response)
        setPrice('')
        if (event.target.value === 'Ostalo') {
            setForm((oldState) => ({
                ...oldState,
                ['model']: null,
                ['drone_class']: '',
                ['flying_class']: '',
                ['weight']: '',
                ['additionalManufactured']: '',
            }));
        } else {
            setForm((oldState) => ({
                ...oldState,
                ['model']: null,
                ['drone_class']: '',
                ['weight']: '',
                ['flying_class']: '',
                ['additionalManufactured']: false,
            }));
        }
    };

    const toggleKaskoVisibility = () => {
        setIsKaskoVisible(!isKaskoVisible);
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile([...file, e.target.files[0]]);
        }
    };
    const removeFile = (e) => {
        setFile(oldValues => {
            return oldValues.filter((_, i) => i !== e)
        })
    }
// Ažurirana funkcija za izračunavanje kasko premije na temelju vrijednosti drona
    const calculateKaskoPrice = () => {
         const numericDroneValue = parseFloat(droneValue); // Konvertiraj u broj
    if (isNaN(numericDroneValue) || numericDroneValue <= 0) {
        console.error("Vrijednost drona nije validna.");
        setKaskoPrice(''); // Ako nije validna, postavi prazan rezultat
        return;
    }
        console.log("Države:", form.location);
        console.log("Vrijednost drona:", droneValue);
        console.log("Checked:", checked);
        let baseMultiplier = 0;

        // Proveri vrstu kaska
        if (checked.checkbox1) {
            baseMultiplier = 0.105; // Puni kasko
        } else if (checked.checkbox2) {
            baseMultiplier = 0.07; // Djelomični kasko
        }

        // Ako je vrijednost drona prisutna i bazni multiplikator je veći od 0
        if (droneValue && baseMultiplier > 0) {
            // Prvo izračunaj osnovni kasko iznos
            const basicKasko = droneValue * baseMultiplier;

            // Sada dodaj multiplikator za teritorijalno pokriće
            let territorialMultiplier = 1; // Inicijalizuj multiplikator

            // Proveri teritorijalno pokriće
            if (form.location === 'Europa') {
                territorialMultiplier = 1.5; // Multiplikator za Europu
            } else if (form.location === 'Svijet') {
                territorialMultiplier = 3; // Multiplikator za svijet
            } else if (form.location === 'Hrvatska') {
                territorialMultiplier = 1; // Multiplikator za Hrvatsku
            }

            // Računaj konačnu cijenu kasko osiguranja
            let calculatedKasko = basicKasko * territorialMultiplier;

            // Osiguraj minimalnu premiju od 80 EUR
            calculatedKasko = Math.max(calculatedKasko, 80);

            setKaskoPrice(calculatedKasko.toFixed(2)); // Formatiraj sa dve decimale
        } else {
            setKaskoPrice(''); // Ako nema osnove, postavi prazan rezultat
        }
    };

    useEffect(() => {
        const fetchAllManuFactorers = async () => {
            const response = await getAllManufacturers();
            const t = []
            t.push(response)
            setManufacturer([...t[0], {id: 0, name: 'Ostalo'}])
            const responseLocation = await getAllDroneLocation();
            setLocation(responseLocation)
        }
        fetchAllManuFactorers();
    }, [])


    useEffect(() => {
        if (price && price[0]) {
            const mandatoryInsurance = form.purpose === 'nekomercijalna'
                ? price[0].non_commercial
                : price[0].commercial;

            // Izračunaj kasko cenu
            calculateKaskoPrice(mandatoryInsurance);
        }
    }, [price, checked, form.location]); // Slušamo promene u premiji, vrsti kaska, i lokaciji


    const handleCheckBoxChangeCasco = (event) => {
        if (event.target.name === 'fullCasoc') {
            setChecked({
                checkbox1: !checked.checkbox1,
                checkbox2: false,
            })
        } else {
            setChecked({
                checkbox1: false,
                checkbox2: !checked.checkbox2,
            })
        }
    };
    useEffect(() => {
        setForm((oldState) => ({
            ...oldState,
            ['kasko_type']: checked.checkbox1 === true ? 'Puni kasko - svi rizici bez ograničenja' : checked.checkbox2 === true
                ? 'Djelomični kasko - svi rizici samo za vrijeme letačke operacije' : false,
        }));
    }, [checked.checkbox1, checked.checkbox2])

    const handleChangeLocation = async (event) => {
        if (form.model) {
            allModels.filter(allmodel => allmodel.id === form.model).map(async filterModels => {
                const response = await getCalculation(filterModels.id, event.target.value);
                setPrice(response)
            })
        }
    };

    const handleChangeModels = async (event) => {
        const response = await getDronInfo(event.target.value);
        setForm((oldState) => ({
            ...oldState,
            ['drone_class']: response.drone_class,
            ['flying_class']: response.flying_class,
            ['weight']: response.weight,
        }));

        if (form.location) {
            allModels.filter(allmodel => allmodel.id === event.target.value).map(async filterModels => {
                const response = await getCalculation(filterModels.id, form.location);
                setPrice(response)
            })
        }
    };


   const handleUserInput = (e) => {
    const { name, value } = e.target; // Uzmi ime i vrijednost iz inputa

    // Ažuriraj stanje za vrijednost drona
    if (name === 'value') {
        setDroneValue(value); // Postavi stanje za vrijednost drona
    }

    // Pozovi funkciju za izračun nakon promjene
    calculateKaskoPrice();

    // Provjeri vrstu inputa i ažuriraj formu
    if (typeof value === 'string' && value.trim() !== '' || typeof value === 'number') {
        if (name === 'manufacturer') {
            handleChangeManufacturer(e);
        } else if (name === 'model') {
            handleChangeModels(e);
        } else if (name === 'location') {
            handleChangeLocation(e);
        }

        // Ažuriraj stanje forme
        setForm((oldState) => ({
            ...oldState,
            [name]: value,
        }));
    }
};


    const filter = (data) => {
        const mod = []
        allModels.filter(allmodel => allmodel.id === data).map(filterModels => {
            mod.push(filterModels.model)
        })
        return mod[0]
    }

    const handleFormAddSubmit = async (e) => {
        e.preventDefault();
        let valid = [];
        const t = []
        for (let item of Object.keys(form)) {
            if (form[item] === '' || form[item] === null) {
                if (item === 'drone_class') {
                    valid.push(false);
                } else {
                    valid.push(true);
                }
                t.push({[item]: null})

            } else {
                t.push({[item]: form[item]})
            }
        }
        const mergedObject = t.reduce((result, currentObject) => {
            return {
                ...result,
                ...currentObject
            };
        }, {});
        setForm(mergedObject)


        const commonData = {
            ...form,
            manufacturer: form.manufacturer === 'Ostalo' ? form.additionalManufactured : form.manufacturer,
            model: form.manufacturer === 'Ostalo' ? '' : filter(form.model),
        };
        delete commonData['flying_class'];


        if (!valid.includes(true)) {
            const formData = new FormData();
            for (let item of Object.keys(commonData)) {
                formData.append(item, commonData[item] === false ? '' : commonData[item]);
            }
            if (file.length > 0) {
                for (let i = 0; i < file.length; i++) {
                    formData.append('files', file[i]);
                }
            }
            const response = await sendMail(formData)
            setForm(initialFormState)
            setFormKey(formKey + 1);
            if (response[0].status === 'Bad') {
                setFile([])

                handleClose();
                setErrorMsg('Bad')
                setOpen(true)
                setPrice('')
            } else {
                setChecked(check)
                setFile([])
                setForm(initialFormState)
                setErrorMsg('Good')
                setOpen(true)
                handleClose();
                setPrice('')
            }
        }
    }

    const validation = (data) => {
        if (data === null) {
            if (form.manufacturer === 'Ostalo') {
                return true
            }
        } else {
            return false
        }
    }


    return (
        <>
            <NavBar/>
            <div className="banner">
                <img src={calculationImage} style={{width: "100vw"}} alt=""/>
            </div>
            <form key={formKey} onSubmit={handleFormAddSubmit}
                  style={{maxWidth: "1152px", margin: "auto", padding: "50px 0px"}}>
                <Stack sx={{background: "white", m: 1, borderRadius: "5px", padding: "15px"}}>
                    <h6 style={{fontSize: "15px"}}><b>IZRAČUN OBVEZNOG OSIGURANJA</b></h6>
                    <Stack direction={{xs: 'column', sm: 'row'}}>
                        <FormControl fullWidth sx={{m: 1}}>
                            <InputLabel id="demo-simple-select-label">Proizvođač</InputLabel>
                            <Select
                                error={form.manufacturer === null}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={form.manufacturer === null ? '' : form.manufacturer}
                                label="Proizvođač"
                                name="manufacturer"
                                onChange={handleUserInput}
                            >
                                {manufacturer && manufacturer.map((e, index) => (
                                    <MenuItem key={index} value={e.name}>{e.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{m: 1}}>
                            <InputLabel id="demo-simple-select-label">Model</InputLabel>
                            <Select
                                error={form.model === null}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={form.model === false || form.model === null ? '' : form.model}
                                label="Model"
                                name="model"
                                onChange={handleUserInput}
                                disabled={form.manufacturer === 'Ostalo'}
                            >
                                {allModels && allModels.map((e, index) => (
                                    <MenuItem key={index} value={e.id}>{e.model}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    {form.manufacturer === 'Ostalo' &&
                        <Stack direction={"row"}>
                            <TextField name='additionalManufactured' id="outlined-basic"
                                       error={validation(form.additionalManufactured)}
                                       label="Unesite proizvoljno proizvođača i model"
                                       variant="outlined" onChange={handleUserInput}
                                       sx={{m: 1, width: "100%"}}/>
                        </Stack>
                    }

                    <Stack direction={{xs: 'column', sm: 'row'}}>
                        <FormControl fullWidth sx={{m: 1}}>
                            <InputLabel
                                id="demo-simple-select-label">{form.drone_class === null ? 'Nema klasu' : 'Klasa'}</InputLabel>
                            <Select
                                error={form.drone_class === null}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={form.drone_class === null ? '' : form.drone_class}
                                label="Klasa"
                                name="drone_class"
                                onChange={handleUserInput}
                                disabled={form.manufacturer !== 'Ostalo'}
                            >
                                <MenuItem value={'C0'}>CO</MenuItem>
                                <MenuItem value={'C1'}>C1</MenuItem>
                                <MenuItem value={'C2'}>C2</MenuItem>
                                <MenuItem value={'C3'}>C3</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{m: 1}}>
                            <TextField
                                error={form.weight === null}
                                id="outlined-basic"
                                label="MTOM"
                                variant="outlined"
                                value={form.weight}
                                name='weight'
                                disabled={form.manufacturer !== 'Ostalo'}
                                onChange={handleUserInput}
                            />
                        </FormControl>

                    </Stack>
                    <Stack sx={{m: 1}}>
                        <FormControl fullWidth>
                            <TextField
                                id="outlined-disabled"
                                label="Kategorija letaćke operacije"
                                variant="outlined"
                                value={form.flying_class}
                                name='flying_class'
                                disabled
                            />
                        </FormControl>
                    </Stack>

                    <Stack sx={{m: 1}}>

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Namjena korištenja</InputLabel>
                            <Select
                                error={form.purpose === null}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={form.purpose === null ? '' : form.purpose}
                                label="Namjena korištenja"
                                name="purpose"
                                onChange={handleUserInput}
                            >
                                <MenuItem value={'nekomercijalna'}>Nekomercijalno (u privatne svrhe)</MenuItem>
                                <MenuItem value={'komercijalna i javna'}>Komercijalno i javno</MenuItem>


                            </Select>
                        </FormControl>
                    </Stack>

                    <Stack sx={{m: 1}}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Teritorijalno pokriće</InputLabel>
                            <Select
                                error={form.location === null}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={form.location === null ? '' : form.location}
                                name="location"
                                label='Teritorijalno pokriće'
                                onChange={handleUserInput} // Ovdje se poziva funkcija za ažuriranje
                            >
                                {location && location.map((e, index) => (
                                    <MenuItem key={index} value={e.name}>{e.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>

                    <hr/>
                    {form.manufacturer === 'Ostalo' ?
                        <h6 style={{textAlign: "right"}}><b>Ponuda osiguranja bit će dostavljena na Vašu e-mail
                            adresu</b></h6>
                        :
                        <h6 style={{textAlign: "right"}}><b> {price && price[0] && <>
                            {form.purpose === 'nekomercijalna' ? 'Godišnja premija: ' + price[0].non_commercial : 'Godišnja premija: ' + price[0].commercial}EUR</>}</b>
                        </h6>
                    }
                </Stack>


                <Stack sx={{background: "white", m: 1, borderRadius: "5px", padding: "15px"}}>
                    <h6 style={{fontSize: "15px"}}><b>BESPILOTNI ZRAKOPLOV</b></h6>
                    <Stack direction={{xs: 'column', sm: 'row'}} style={{width: "100%"}}>
                        <FormControl fullWidth>
                            <TextFieldInput style={{width: "100%"}} formParams={form.serial_number}
                                            nameInput={'serial_number'}
                                            labelInput={'Serijski broj'} handleUserInput={handleUserInput}/>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextFieldInput formParams={form.year} nameInput={'year'}
                                            labelInput={'Godina proizvodnje'} handleUserInput={handleUserInput}/>
                        </FormControl>
                    </Stack>
                    <Stack direction={"row"}>
                        <TextField name='note' id="outlined-basic" label="Napomena"
                                   variant="outlined" onChange={handleUserInput}
                                   sx={{m: 1, width: "100%"}}/>
                    </Stack>
                </Stack>


                <Stack sx={{background: "white", m: 1, borderRadius: "5px", padding: "15px"}}>
                    <h6 style={{fontSize: "15px"}}><b>VLASNIK/OPERATOR BESPILOTNOG ZRAKOPLOVA</b></h6>

                    <Stack sx={{m: 1}}>
                        <InputLabel id="demo-simple-select-label">Vrsta osiguranika</InputLabel>
                        <Select
                            error={form.insurance === null}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={form.insurance === null ? '' : form.insurance}
                            name="insurance"
                            onChange={handleUserInput}
                            label='Vrsta osiguranika'
                        >
                            <MenuItem value={'Fizička osoba'}>Fizička osoba</MenuItem>
                            <MenuItem value={'Pravna osoba'}>Pravna osoba</MenuItem>
                            <MenuItem value={'Obrtnik'}>Obrtnik</MenuItem>
                        </Select>
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }}>
    <FormControl fullWidth>
        <TextFieldInput
            formParams={form.name}
            nameInput={'name'}
            labelInput={
                form.insurance === 'Pravna osoba'
                    ? 'Naziv tvrtke'
                    : form.insurance === 'Obrtnik'
                        ? 'Naziv obrta, ime i prezime vlasnika'
                        : 'Ime i prezime' // Default naziv za fizičku osobu
            }
            handleUserInput={handleUserInput}
        />
    </FormControl>
    <FormControl fullWidth>
        <TextFieldInput
            formParams={form.email}
            nameInput={'email'}
            labelInput={"E-mail adresa"}
            handleUserInput={handleUserInput}
        />
    </FormControl>
</Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}}>
                        <FormControl fullWidth>
                            <TextFieldInput formParams={form.oib} nameInput={'oib'}
                                            labelInput={"OIB"} handleUserInput={handleUserInput}/>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextFieldInput formParams={form.address} nameInput={'address'}
                                            labelInput={"Adresa"} handleUserInput={handleUserInput}/>
                        </FormControl>

                    </Stack>
                    <Stack direction={{xs: 'column', sm: 'row'}}>
                        <FormControl fullWidth>
                            <TextFieldInput formParams={form.city} nameInput={'city'}
                                            labelInput={"Grad"} handleUserInput={handleUserInput}/>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextFieldInput formParams={form.zip_code} nameInput={'zip_code'}
                                            labelInput={"Poštanski broj"} handleUserInput={handleUserInput}/>
                        </FormControl>
                    </Stack>
                </Stack>

                <Stack direction="row" alignItems="center">
                    <Switch checked={isKaskoVisible} onChange={toggleKaskoVisibility}/>
                    <Typography variant="body1">Potrebno Vam je kasko osiguranje?</Typography>
                </Stack>

                {isKaskoVisible && (
                    <Stack sx={{background: "white", m: 1, borderRadius: "5px", padding: "15px"}}>
                        <h6 style={{fontSize: "15px"}}><b>KASKO</b> - neobavezno</h6>
                        <Stack direction={"row"}>
                            <TextField name='value' id="outlined-basic"  type="number" label="Vrijednost drona i dodatne opreme"// Ograničava unos na brojeve
        inputProps={{ min: 0 }} //// Postavlja minimalnu vrijednost na 0
                                       variant="outlined" onChange={handleUserInput}
                                       sx={{m: 1, width: "100%"}}/>
                        </Stack>

                        <FormGroup>
                            <p>Vrsta kasko osiguranja</p>
                            <FormControlLabel control={<Checkbox name="fullCasoc" checked={checked.checkbox1}
                                                                 onChange={handleCheckBoxChangeCasco}/>}
                                              label="Puni kasko - svi rizici bez ograničenja"/>
                            <FormControlLabel control={<Checkbox name="partiallyCasco" checked={checked.checkbox2}
                                                                 onChange={handleCheckBoxChangeCasco}/>}
                                              label="Djelomični kasko - svi rizici samo za vrijeme letačke operacije"/>
                        </FormGroup>

                        <Stack direction={"row"}>
                            <TextField name='rest_address' id="outlined-basic" label="Adresa za vrijeme mirovanja"
                                       variant="outlined"
                                       sx={{m: 1, width: "100%"}} onChange={handleUserInput}/>
                        </Stack>

                        <Stack direction={"row"}>
                            <TextField name='damages' id="outlined-basic" label="Povijest štete unazad 5 godina"
                                       variant="outlined"
                                       sx={{m: 1, width: "100%"}} onChange={handleUserInput}/>
                        </Stack>

                        <div style={{textAlign: "center"}}>
                            <p>Privitci</p>
                            <p>(.doc, .jpg, .jpeg, .pdf)</p>
                        </div>
                        <label className="custom-file-upload">
                            <input type="file" onChange={handleFileChange}/>
                            <i className="fa fa-cloud-upload"></i>
                            <Typography style={{textAlign: 'center'}}>DODAJ</Typography>
                        </label>

                        <div>
                            {file.map((e, index) => (
                                <><Typography key={index}>{e.name}
                                    <Button style={{float: 'right'}}><DeleteForeverIcon onClick={() => {
                                        removeFile(index)
                                    }}/></Button>
                                </Typography>
                                    <hr/>
                                </>
                            ))}
                        </div>

                        <small style={{fontSize: "11px"}}><b>Za ugovaranje kasko osiguranja potrebno je dostaviti račun
                            i
                            par
                            slika letjelice i opreme koja se osigurava, a na jednoj slici mora biti vidljiv serijski
                            broj</b></small>

                        {(checked.checkbox1 || checked.checkbox2) && kaskoPrice && (
                            <h6 style={{textAlign: "right"}}><b>Kasko premija: {kaskoPrice} EUR</b></h6>
                        )}
                    </Stack>
                )}


                <DialogActions>
                    <Button variant="contained" type="submit">Pošalji</Button>
                    <Button onClick={handleClose}>Zatvori</Button>
                </DialogActions>

            </form>
            <Footer/>
        </>
    )
}

